body {
  margin: 0;
  font-family: 'A3G-roobert'!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.5px;
}
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300..800;1,300..800&display=swap'); */
:root {
  --primary:#bb7111;
  --secondary:#ee4749;
  --secondary-light:#ee474a75;	
}
@font-face {
  font-family: A3G-roobert;
  src: url('./components/fonts/RoobertTRIAL-Light.otf');
}


h1,h2,h3,h4,h5,h6{
  
  font-weight: 600!important;
}
.f-16{
  font-size: 17px;
  font-weight: 600!important;
}
.logo1{
  width: 70px;
}
.p-90{
  font-size: 14px;
  height: 90px!important;
}

.carousel-control-next-icon, .carousel-control-prev-icon {  
  background-color: #ffc107;  
  background-size: 20px!important;
}
.main-color{
  color: var(--primary);
}
.slider-item img{
  transition: all 0.5s ease-in-out; 
}

.btn{
  background-color: #e2093429; 
  color: #00AFF0;
  text-shadow: 0px 0px 1px #00aff0;
  transition: all 0.5s linear;
  overflow: hidden;
  z-index: 1;
  position: relative;
  border-radius: 5px;
  
}
.btn:after{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: -1px;
  background: #e2093429;  
  content: "";
  transform: scaleX(0.1) rotateX(90deg);
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  z-index: -1;
  border-radius: 5px;
}
.btn:hover:after {
  transform: scaleX(1.0) rotateX(0deg);
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}




.but{
  background-color: var(--primary);
  padding: 10px;
  color: #ffffff!important;
  text-shadow: 0px 0px 1px var(--secondary);
  transition: all 0.5s linear;
  overflow: hidden;
  z-index: 1;
  position: relative;
  border-radius: 5px;
  
}
.but:after{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: -1px;
  background: #e2093429;  
  content: "";
  transform: scaleX(0.1) rotateX(90deg);
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  z-index: -1;
  border-radius: 5px;
}
.but:hover:after {
  transform: scaleX(1.0) rotateX(0deg);
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.img{
  background-image:url(./components/img/bg-1.png) ;
}
.partner{
  background-color: #bb7111;
}
.partnerS{
  background-color: #bb7111;
}
.border-r{
  border-radius:200px 0px 200px 0px;
  border:1px solid #4f4f4f!important;
}
.topdes img{
  width: 135px;
}
.apply-O{
  position: relative;  
  border-radius: 5px!important;      
  font-size: 20px!important;
}
.bannerText2{
  position: absolute;
  background-image: url(./components/img/Gradient.png);
  bottom: 15px;
    width: 93.5%;
    border-radius: 0px 0px 15px 15px;
    background-size: 100% 100%;

}
.topHeader img{
  width: 25px;
  
}
.border-r{
  border-radius: 5px;
  border:none;
  border: 1px solid #000;
}
.footerBottom img{
  width: 25px;
  
}

.hover15 figure {
	position: relative;
}
.hover15 figure::before {
	position: absolute;
	top: 50%;
	left: 10%;
	z-index: 2;
	display: block;
	content: '';
	width: 0;
	height: 0;
	background: rgba(255,255,255,.2);
	border-radius: 100%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	opacity: 0;
}
.hover15 figure:hover::before {
	-webkit-animation: circle .75s;
	animation: circle .75s;
}
@-webkit-keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}
@keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}


.aboutt p{
  line-height: 2;
}
.bg-img{
  
  background-repeat: no-repeat;
  margin-left:20px;
  position: absolute;
  z-index: 999;
  left: 5%;
}
.bg-shade{
  
  background-repeat: no-repeat;
  margin-left: 0px;
  position: absolute;
  left: 5%;
}
.about-img{
  
  margin-top: -8.9%;
  position:relative;
  left: 20%;
  z-index: 9999;
}
.plane{
  position: absolute;
  right: 15%;
  top:7%;
  width: 180px!important;
}

.slider-icon{
  position: absolute;
    top: 42%;
    left: 7%;
}
.sliderImg{
  border-radius: 10px;
}
.carousel-item .p-2{
  padding-bottom: 105px!important;
  padding-left:1rem!important;
  padding-right:1rem!important;
}
.carousel-indicators {  
  bottom: -50px!important;
}
.carousel-indicators [data-bs-target] { 
  background-color:var(--primary);
}

.carousel-indicators .active {
  background-color: #555;
}
.carousel-indicators [data-bs-target] {  
  width: 7px;
  height: 7px;
}

.flydream-bg{
  background-image: url(./components/img/fly-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.border-0{
  border-radius: 0px!important;
}


.immg img{
  min-width: 100%;
}

.bg-primary1-1{
  background-color:var(--primary);
  border-radius: 10px;
}
.bg-light-1{
  background-color: #ccc;
  border-radius: 10px;
}
.contact-bg-1 .form-control{
  background: transparent!important;
    border-bottom: 2px solid #000 !important;
    border-radius: 0px!important;
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
}


.input-group-text{
  border: none !important;
    background: #fff;
}
#QuickLinks{
  /* list-style-type: disc; */
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  list-style-position: inside;
}

#QuickLinks li{
  margin-left: 0px;
}
.bi-chevron-compact-right{
  font-size: 77%;
    font-style: normal;
}
.text-secondary-1{
  color: #a3a3a3 !important;
}
.bg-dark-1{
  background-color: #1e1e1e;
}
.text-primary1 {
  --bs-text-opacity: 1;
  color: rgb(0 175 240) !important;
}

.bg-primary1 {
  background-color: rgb(0 175 240) !important;
}
.text-primary1 {
  --bs-text-opacity: 1;
  color: rgb(0 175 240) !important;
}

.btn-12 {
  position: relative;
  right: 20px;
  bottom: 20px;
  border: none;
  box-shadow: none;
  width: 200px;
  height: 40px;
  line-height: 42px;
  -webkit-perspective: 230px;
  perspective: 230px;
}

.btn-12 span {
  background: var(--primary);
  background: linear-gradient(0deg, var(--primary) 0%, var(--primary) 100%);
  display: block;
  position: absolute;
  width: 200px;
  height: 40px;
  box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1);
  border-radius: 5px;
  margin: 0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
  color: #fff;
}

.btn-12 span:nth-child(1) {
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9, 7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}

.btn-12 span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}

.btn-12:hover span:nth-child(1) {
  box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.btn-12:hover span:nth-child(2) {
  box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1);
  color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}




.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #fab218;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #fab218;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button:hover:after {
  background-color: darken(#fabe18, 10%);
}

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}



.video-overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.80);
  opacity: 0;
  transition: all ease 500ms;
}

.video-overlay.open {
  position: fixed;
  z-index: 1000;
  opacity: 1;
}

.video-overlay-close {
  position: absolute;
  z-index: 1000;
  top: 15px;
  right: 20px;
  font-size: 36px;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: all 200ms;
}

.video-overlay-close:hover {
  color: #fad018;
}

.video-overlay iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  /* width: 90%; */
  /* height: auto; */
  box-shadow: 0 0 15px rgba(0,0,0,0.75);
}

.carousel-control-next{
  right: 0.5%!important;
  align-items: right!important;
  justify-content: right!important; 
  opacity: 1!important;
  
}

.carousel-control-prev{
  left: 0.5%!important;
  align-items: left!important;
  justify-content: left !important;  
  opacity: 1!important;
  
}
.bg-imgg:after{
  position: absolute;
  background-color: #000000ab;
  height: 580px;
  width: 100%;
  content: '';
  left: 0;

}



