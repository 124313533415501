:root {
  --primary:#bb7111;
  --secondary:#ee4749;
  --secondary-light:#ee474a75;	
}


.App-logo {
  height: 50px;
  pointer-events: none;
}
body{
  font-family: "Lato";
}

.bg-about{
  background-color: #f4f6f3;
}
.bg-primary-1{
  background-color: rgb(232 168 93 / 81%);
}
.nav-link{
  color: var(--primary)!important;
  margin-left: 5px;
}
.nav-link a{
  text-decoration: none;
    color: #000;
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
}
.dropdown-item:hover{
  background-color: var(--primary);
}
.dropdown-menu{
  padding:0px;
  border:none;
  box-shadow: 0px 16px 10px 2px #00000017;
}
.dropdown-menu li:hover .dropdown-item a{
  color:var(--primary); 
}

.dropdown-item.hover, .dropdown-item:hover{
  color:#fff;
  background-color: var(--primary);
}
.dropdown-menu li a{
  color: #393939;
}
.dropdown-item a{
  text-decoration: none;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff!important;
  text-decoration: none;
  background-color: #fff!important;
}
#Cont_form .form-control{
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
}
#Cont_form .form-control:focus{
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  box-shadow: none;
}
.list-style-none li{
  list-style: none;
}
.list-style-none li .bi{
  color: #707070;
}
.list-style-none{
  display: flex;
}
.text-dec-none{
  list-style: none;
}
#QuickLinks li a{
  text-decoration: none;
  color: var(--primary);
}
.text-decoration-none{
  text-decoration: none;
}
.primary-text{
  color: var(--primary);
}
.btn-primary{
  background-color: var(--primary)!important;
  border: none !important;;
}
.btn-primary1{
  background-color: transparent!important;
  border-color: #fff !important;
  border-radius: 0px!important;
  color: #fff !important;;
}
.btn-danger{
  border:2px solid var(--secondary)!important;
  border:none;
  background-color: transparent!important;
  color:var(--primary)
}
.btn-danger:hover{
  background-color: var(--primary)!important;
  color: #fff;
  border-color:transparent!important;
}
.fixed-top{
  z-index: 9999999!important;
}
.Aboutpage{
  background-color: #fff;
}
.btn-danger{
  color: var(--secondary)!important;
}
.btn-danger:hover{
  color: #fff!important;
}
.ab-page img{
  height: 80px;
  width: 80px;
  margin-bottom: 20px;
}
.res-ser img{
  height: 80px;
  width: 80px;
  margin-bottom: 20px;
}
.applyNoww div{
  cursor: pointer;
}
.modal-content{
  border-radius: 10px;
}
.how-we-work  img{
  height: 80px;
}


@media only screen and (min-width: 768px) {
  .unit-logo{
    width: 100px;
    background-color: #000;
  }
  
  
  .modal-custom label{
    font-size: 15px;
  }
  .modal-custom p{
    font-size: 12px;
  }
  #carouselExampleFade .carousel-inner{
    height: 600px;
  }
  .col-20{
    max-width: 20%!important;
  }
  .resss h5 {
      height: 50px;
      font-size: 100%;
  }
  .consultation-img{
    border-radius: 5px 0px 0px 5px;
  }
  .modal-custom {
    max-width: 60%!important; 
    
    margin: auto; 
  }
  
  .applyNoww {
    height: 57px !important;
    width: 141px !important;
    padding: 3px 20px 20px 20px !important;
    position: fixed !important;
    top: 45% !important;
    display: block;
    line-height: 23px;
    background: rgb(187 113 17);    
    left: -43px !important;    
    transform: rotate(-90deg);
    text-transform: uppercase;
    letter-spacing: 2px;
    display: block;
    z-index: 9999;
    text-align: center;
    font-size: 11px;
    color: #fff;
}
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 10px 0;
    min-width: 150px;
    z-index: 1000;
}

.dropdown-item {
    padding: 10px 20px;
    text-decoration: none;
    color: #000;
    display: block;
}

.dropdown-item:hover {
    background-color: #f8f9fa;
    color: #007bff;
}

.nav-item:hover .dropdown-menu {
    display: block;
    max-width: 200px;
}
  .ab-page p{
    height: 220px;
  }
  .commer p{
    height: 145px!important;
  }
  .commer h5{
    height: 50px;
    font-size: 100%;
  }

  .res-ser p{
    height: 120px;
  }
  .res-ser h5{
    height: 50px;
  }
  .whatsappIcon {
    position: fixed;
    top: 65%;
    right: 2%;
    z-index: 9999;
}
.whatsappIcon img{
  width: 55px;
}

  .why-content{
    z-index: 9999!important;
    position: relative;
    margin-top: -200px;
  }
  .mtt---100 h2{
    margin-top:-250px;
  }
  .why-box{
    border: 2px solid #ffcb21;
    padding: 10px 15px;
    /* background-color: #000;
    color: #fff; */
    /* margin-top: -150px; */
  }
  .why-box img{
    width: 90px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .why-box h5{
    font-size: 100%;
  }
  .why-box p{
    line-height: 1.5;
    height: 220px;
    margin-top: 15px;
  }

  .video-play-button {
    cursor:pointer;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 75%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    border-radius: 50%;
    padding: 18px 20px 18px 28px;
  }
  .logo{
    max-width: 280px!important;
  }
  .mb-100{
    margin-bottom:100px;
  }
  
    
    .navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
      display: block!important;
    }
    #demo img{
      border-radius: 40px;
    }
    .SliderText{
      position: relative;
      z-index: 0;
      width: 65%;
      padding: 35px 30px;
      margin-left: 20%;
      top: 17%;      
      color: #fff;
    }
    .DesNone{
      display: none;
    }
    #brandParnner img{
      height: 100%;
    padding: 30px;
    width: 58%;
      border: 0.35rem solid transparent;	
    }
    
    .carousel-caption {
      position: absolute;
      right: 15%;
      bottom: 0rem!important;
      left: 10%!important;
      padding-top: 10%!important;
     
      color: #fff;
      text-align: left!important;
      padding-left: 2%;
      width: 31%;
      
      background: #bb7111;
      height: -webkit-fill-available;
  }
  .carousel-caption h2{
    font-size: 280%;
        text-shadow: 0 0 2px #000000, 0 0 4px #0d0d0d;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 1.4;
  }
    .topBar p{
      font-size: 15px;
    }
    .bannerText h1{
      font-size: 60px;
      font-weight: 700;
    }
    .partner .col-auto{
      max-width: 20%;
    }
    .partner img{
      max-width: 100%;
    }
    .slider-text{
      margin-top: -15%;
        background: #fff;
        z-index: 9;
        position: absolute;
        left: 3%;
        padding: 15px 25px;
        width: 94%;
    }
    .slider-text h5{
      font-size: 1.15rem;
    }
    .arrowH1{
      position: absolute;
      width: 150px !important;
      left: 15%;
      filter: brightness(0.1);
  }
  
  .arrowH2{
    position: absolute;
    width: 150px !important;
    right: 24%;
    filter: brightness(0.1);
  }
  .fly-ii{
    position:absolute;
    bottom: 0px;
    right: 0px;
  }
  .recent-post h5{
    color: rgb(0 175 240) !important;
    font-weight: 600;
  }
  .recent-post-box img{
    min-width: 100%;
  }
  .success-story .col-md-5 {
    flex: 0 0 auto;
    width: 39.666667%;
  }
  .success-story .col-md-7 {
    flex: 0 0 auto;
    width: 60.333333%;
  }
  .contact-bg-1{
    background-image: url('./components/img/contact-bg-1.png');
    background-position: 50%;
  }
  .circle1{
    position: absolute;
      bottom: 9%;
      right: 15%;
      width: 95px;
  }
  .circle2{
    position: absolute;
    bottom: -1%;
    right: 2%;
    width: 135px;
  }
  .testimonial-img1{
    position: absolute;
      width: 43%;
      top: 9%;
      z-index: 1;
  }
  .quote{
    position: absolute;
      top: 3%;
      right: 60%;
      width: 60px;
      z-index: 9;
  }
  .rating-count{
    background-color: var(--primary);
      padding: 12px;
      position: absolute;
      width: 112px;
      text-align: center;
      color: #fff;
      bottom: -9%;
      left: 34.7%;
      z-index: 9;
  }
  .rating-count p{
    font-size: 85%;
    z-index: 9;
    position: relative;
  }
  .logo-f{
    min-width: 208px;
    max-width: 100px !important;
    box-shadow: 0px 6px 24px #fff;
    background: #fff;
    border-radius: 0px;
    padding: 10px;
  }
    
}
.contact-us-home img{
  width: 35px;
}

/* Base Styling */
.nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column; /* Mobile-friendly */
}

.nav-item {
  position: relative;  
}

.nav-link {
  text-decoration: none;
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.nav-link .icon {
  font-size: 12px;
}

/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 10px 0;
  min-width: 150px;
  z-index: 1000;
  display: none; /* Default: hidden */
}
h1.text-white{
  text-shadow: 0 0 2px #ffffff, 0 0 4px #ffffff;
    color: #000 !important;
}
h1{
  text-shadow: 0 0 8px #ffffff, 0 0 8px #ffffff;
    color: #000 !important;
}

/* Show Dropdown */
.nav-item .dropdown-menu {
  display: block; /* Conditionally rendered, no need for `hover` rules */
  max-width: 200px;
}

/* Dropdown Item Styling */
.dropdown-item {
  padding: 10px 20px;
  text-decoration: none;
  color: #000;
  display: block;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
  color: #007bff;
}
.dropdown-menu {
  transition: all 0.3s ease-in-out;
  transform: scaleY(1);
  transform-origin: top;
  opacity: 0;
}

.nav-item .dropdown-menu {
  transform: scaleY(1);
  opacity: 1;
}



@media only screen and (max-width: 767px) {
  .modal-custom {
    max-width: 100%!important; 
    
    margin: auto; 
  }

  .whatsappIcon {
    position: fixed;
    top: 65%;
    right: 0%;
    z-index: 9999;
  }
  .whatsappIcon img{
    width: 55px;
  }

  .applyNoww {
    height: 46px !important;
        width: 200px !important;
        padding: 25px 0px 13px 0px !important;
        position: fixed !important;
        top: 45% !important;
        display: block;
        line-height: 23px;
        background: rgb(187 113 17);
        left: -95px !important;
        transform: rotate(-90deg);
        text-transform: uppercase;
        letter-spacing: 2px;
        display: block;
        z-index: 9999;
        text-align: center;
        font-size: 11px;
        color: #fff;
        border-radius: 5px;
}

  
  .how-we-work.py-5{
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .sticky-top {
     
      z-index: 9999999!important;
  }
  .services .h5, h5 {
    font-size: 1.1rem!important;
  }   

  .why-content.bg-dark.pb-5{
    padding-bottom: 0px!important;
  }

  .why-content{
    z-index: 9999!important;
    position: relative;
    margin-top: 0px;
  }
  .mtt---100 h2{
    margin-top:-100px;
  }
  .why-box{
    border: 2px solid #ffcb21;
    padding: 10px 15px;
    background-color: #000;
    color: #fff;
    margin-top: -40px;
    margin-bottom: 50px;
  }
  .why-box img{
    width: 90px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .why-box h5{
    font-size: 100%;
  }
  .why-box p{
    line-height: 1.5;
    height: 150px;
    margin-top: 15px;
  }

  .video-play-button {
    cursor:pointer;
    position: absolute;
    z-index: 10;
    top: 85%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    border-radius: 50%;
    padding: 18px 20px 18px 28px;
  }
  .aboutt{
    margin-top: -15px;
  }
  #carouselExampleFade{
    margin-bottom: 150px;
  }
  .mob-cr{
    flex-direction: column-reverse;
  }
  .partner{
    margin-top: 150px;
  }
  #carouselExampleFade img{
    height: 180px;
  }
  #carouselExampleFade .carousel-inner{
    overflow: visible;
  }

  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: -75% !important;
    left: 5% !important;
    padding-top: 6% !important;
    
    color: #fff;
    text-align: left !important;
    padding-left: 5%;
    width: 90%;
    
    background: #bb7111;
    height: 150px;
    border-radius: 0px 0px 10px 10px;
}
.carousel-caption h2{
  font-size: 150%;
  letter-spacing: 1px;
}

  .logo{
    max-width: 180px!important;
  }
  .m-center{
    text-align: center;
  }
  .address{
    margin-bottom: 50px;
    height: fit-content!important;
  }
  .recent-post.bg-light.py-5{
    padding-bottom: 0px!important;
  }
  .m-center{
    text-align: center;
  }
  .mpx-3{
    padding: 10px 15px!important;
  }
  .subscribe {
    max-width: 98%;
    margin-left: 1%!important;
    border-radius: 5px!important;
  }
  .logo-f{
    min-width: 208px;
    max-width: 100px !important;
    box-shadow: 0px 6px 24px #fff;
    background: #fff;
    border-radius: 0px;
    padding: 10px;
  }
  .mpy-4{
    padding: 15px 10px;
  }
  .m-text-center{
    text-align: center;
  }
  .footerLast .col-md-4.text-end{
    text-align: center!important;
    padding-bottom: 15px;
  }
  footer .col-md-12.p-0{
    padding-bottom: 15px!important;
  }
  .mob-Z.bg-dark{
    z-index: -1;
  }
  .SliderText {
    position: relative;
    z-index: 99999;
    width: 94%;
    padding: 5px 10px;
    margin-left: 3%;
    top: 8%;
    background-color: #223f9278;
    color: #fff;
}
  .circle1{
    position: absolute;
      bottom: 9%;
      right: 15%;
      width: 95px;
  }
  .circle2{
    position: absolute;
    bottom: -1%;
    right: 2%;
    width: 135px;
  }
  .testimonial-img1{
    position: relative;
      width: 100%;
      top: 9%;
  }
  .quote {
    position: absolute;
    top: -2%;
    left: 5%;
    width: 60px;
}
  .rating-count {
    background-color: var(--primary);
    padding: 12px;
    position: absolute;
    width: 112px;
    text-align: center;
    color: #fff;
    bottom: 51%;
    right: 4.7%;
}
  .rating-count p{
    font-size: 85%;
  }
  
  .contact-bg-1{
    background-image: url('./components/img/contact-bg-1.png');
    background-position: 54% -278%;
        background-repeat: no-repeat;
        background-size: 116% 103%;
  }
  .formConnect{
    padding: 25px;
  }
  .recent-post-box img{
    min-width: 100%;
  }
  .recent-post-box{
    margin-bottom: 25px;
  }
  .recent-post h5{
    color: #223f92;
    font-weight: 600;
  }
  .fly-ii{
    position:absolute;
    bottom: 0px;
    right: 0px;
    width: 175px;
  }
  .text-center.text-dark.pb-4{
    margin-bottom: 40px;
  }
  .contactUS.bg-white.py-5{
    padding-top: 0px!important;
  }
  .whyss.pb-5{
    padding-bottom: 0px!important;
  }
  #designPhil .carousel-item .p-2 {
    padding-bottom: 60px !important;    
  }
  .services .carousel-item .p-2 {
    padding-bottom: 100px !important;    
  }
  .bg-primary-1 h1{
    margin-top: 35px;
  }
  .arrowH1{
    position: absolute;
    width: 150px !important;
    left: 37%;
    top: 8%;
    filter: brightness(0.1);
}

.arrowH2{
  position: absolute;
  width: 150px !important;
  right: 24%;
  top: 56%;
  filter: brightness(0.1);
}
  .slider-text{
    margin-top: -15%;
      background: #fff;
      z-index: 999999;
      position: absolute;
      left: 3%;
      width: 94%;
      padding: 15px 25px;
  }
  .mob-cr{
    flex-direction: column-reverse;
  }
  .aboutt .col-6.ps-5{
    padding-left: 2rem !important;
  }
  .aboutt img{
    margin-top: 15px;
  }
  .destinationCountry img{
    margin-bottom: 10px;
  }
  .mw-25{
    max-width: 25%!important;
  }
  .topdes h2{
    font-weight: 600;
  }
  .mvg .col-md-6.p-5{
    padding: 1rem!important;
  }
  .mvg h4{
    font-weight: 600;
  }

  .mvg .col-md-6.p-5 .row.mb-5{
    margin-bottom: 1rem !important;
  }
  .mvg-1{
    border-radius: 0px!important;
    border: none !important;;
  }
  .partner .row.py-5{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .partner img{
    max-width: 100%;
    margin-bottom: 25px;
    padding: 10px;
  }
  
  .partner .col-auto{
    max-width: 50%;
  }
  .bannerText h1{
    margin-top: 40px;
    font-weight: 600;
    font-size: 160%;
  }
  #mobileMenu{
    margin-right: 0% !important;
  }
  .topBar a{
    font-size: 15px;
  }
  .topBar p{
    font-size: 14px;
    margin-bottom: 10px!important;
  }
  .footer-right .float-end{
    float: left!important;
    padding-left: 0px;
  }
  #demo{
    z-index: 1;
  }
  .cratebet{
    background:#000;
    border-radius: 20px;
    background-position: 100% 50%;
  }
  .mob-cr{
    flex-direction: column-reverse;
  }
  .SliderText h1{
    font-size: 24px;
  }
  .mb-100{    
    margin-bottom:100px;
  }
  
  .navbar-light .navbar-toggler {
    position: absolute;
    right: 15px;
    top: 25px;
  }
  .navbar-light .navbar-toggler:focus{
    border:1px dotted #223f92;
    box-shadow: none;
  }
  #demo img{
    border-radius: 20px;
  }
  .Mnone{
    display: none;
  }
  
}


.connect-with-us .btn{
  font-size: 15px;
  padding: 10px 20px;
  width: 280px;
}

#TopNavBar{
  background-image: url('./components/img/stone-background.avif');
  background-repeat: no-repeat;
  background-size: cover;
}

.contactBG::before{
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
  background-color: #000000af;
}
.contactBG .text-white{
  color: #fff!important;
}
.nav.nav-tabs{
  border-bottom: none;
  justify-content: center;

}
.partnerSpouseVisa .nav-item{
  border: 1px solid var(--primary);
    border-radius: 5px;
    margin-left: 3%;
    height: 100%;
    margin-bottom: 10px;
}
.partnerSpouseVisa .nav-link.active{
  border:none;  
  background-color: var(--primary)!important;
  color: #fff!important;
  
}
.partnerSpouseVisa .nav-link{
  margin: 0px !important;
}
.partnerSpouseVisa .nav-link:hover{
  border-color: transparent!important;  
}
.text-primary1 {
  --bs-text-opacity: 1;
  color: rgb(0 175 240) !important;
}
.countryinfo img{
  height: 70px;
  text-align: center;
  margin: 5px auto;
}
.countryinfo{
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
  position: relative;
  padding: 15px;
}
.countryinfo h5{
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: 600;
}
.icon{
  height: 32px!important;
  width: 32px!important;
  border-radius: 50%;
}
.uniname{
  height: auto;
  max-width: 100%;
  background-color: #f5f6f7;
  height: 120px;
  align-items: center;
  display: flex;
}
.services .slider-icon{
  width: 80px;
  background-color: #fafafa;
  padding: 10px;
}
.job .slider-icon{
  width: 80px;
  background-color: #000;
}
.job .slider-text p{
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 15px;
}
.job .slider-text p a{
  font-size: 14px;
  border:1px solid var(--primary);
  padding: 5px 10px;
  border-radius: 0;
  margin-left: 4%;
}

.job h5{
  font-size: 1.1rem;
}


.nav.nav-tabs{
  border-bottom: none;
  justify-content: center;

}
.address
.nav-item{
  border: none;
    border-radius: 5px;
    margin-left: 0%;
    height: 100%;
}
.address
.nav-link.active{
  border:none;  
  background-color: #000!important;
  color: #fff!important;
  
}
.address
 .nav-link{
  margin: 0px !important;
}
.address
 .nav-link:hover{
  border-color: transparent!important;  
}
.address{
  /* border:1px solid var(--primary); */
  border-radius: 5px;
}
#contactPage .pt-5{
  padding-top: 5rem!important;
}
.address h5{
  background: #fff;
    position: absolute;   
    padding: 15px 18px;
    border-radius: 10px 10px 0 0;
    left: 35%;
    width: 30%;
    top: 0%;
    /* border-top: 1px solid var(--primary); */
    font-weight: 600;
}
.email p{
  margin-bottom: 0.3rem;
}
.address{
  height: 200px;
}
.address img{
  height: 40px;
  width: 40px;;
}
.bg-primary11 {
  background-color: rgb(0 175 240) !important;
}
.destinationCountry .col-auto{
  min-width: 14.28%;
}
section{
  z-index: -1;
}

.bg-overlay:before {
  content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgb(0 175 240 / 81%);
    height: fit-content;
    display: block;
    position: absolute;
    height: 312px;
}

.counter {
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  margin: 0px;
}
.counter-icon {
  font-size: 2rem;
  margin-bottom: 10px;
}
.counter-number {
  font-size: 2rem;
  font-weight: bold;
}
.counter-title {
  font-size: 15px;
  margin-top: 10px;
  height: 50px;
}
.green {
  
  background-color: #fff;
  color: #155724;
  padding: 8px;
  border-radius: 5px;
}
.green:hover {
  background-color: #d4edda;
  animation: all 2s ease-in-out;
}
.yellow {
  
  background-color: #fff;
  color: #856404;
  padding: 8px;
  border-radius: 5px;
}
.yellow:hover {
  background-color: #fff3cd;animation: all 2s ease-in-out;
}
.red {
  
  background-color: #fff;
  color: #721c24;
  padding: 8px;
  border-radius: 5px;
}
.red:hover {
  background-color: #f8d7da;  
  animation: all 2s ease-in-out;
}
.orange {
  
  background-color: #fff;
  color: #b45f06;
  padding: 8px;
  border-radius: 5px;
}
.orange:hover {
  background-color: #ffe5b4;
  animation: all 2s ease-in-out;
}

.sticky-top{
  z-index: 999999!important;
}
.modal{
  z-index:9999999!important;
}



input[type="text"], input[type="tel"], select {
  width: 100%; 
  margin-bottom: 10px; 
  
  border: 1px solid #ced4da; 
  border-radius: 4px; 
}

.btn-color {
  background-color: #007bff; 
  border: none; 
  padding: 10px 15px; 
  border-radius: 4px; 
  cursor: pointer; 
}


.btn-color:hover {
  background-color: #0056b3; 
}
.list-font{
  font-size: 100%!important;
}
.modal-heading{
  font-size: 120%;
}
.modal-heading span{
  font-size: 125%;
  color: #e06f2a;
}












